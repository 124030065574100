import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Icon, Form, Tabs } from 'antd';

import intl from 'react-intl-universal';

import ProductPoolBanner from 'components/ProductPoolBanner';
// import AdBanner from 'components/AdBanner';
import withTracker from '../../common/tracker.js';

import { getShopifyStoreStatus, getLanguage } from '../../utils/utils';

import styles from './SubOverview.less';

import setShipping from '../../assets/home/setShipping.gif';
import mappingProduct from '../../assets/home/mappingProduct.gif';
import multipleOrder from '../../assets/home/multipleOrder.gif';
import playYes from '../../assets/home/playYes.png';
import playNo from '../../assets/home/playNo.png';

const { TabPane } = Tabs;

@connect(({ loading, login }) => ({
  userInfo: login.userInfo,
  loading: loading.models.substat,
  storeId: global.storeId
}))
@Form.create()
@withTracker
export default class SubAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: '1',
      cardDisplay: 1
    };
  }

  componentDidMount() {
    // this.getSupplyChain();
  }

  // 新手引导卡片消失
  postNoviceGuidance = (num, v) => {
    this.props.dispatch({
      type: 'substat/postGuidance',
      payload: {
        data: {
          page_name: num,
          status: v
        },
        callback: d => {
          if (d.code == 1) {
            if (d.data.home_2 == 0) {
              this.setState({
                cardDisplay: 0
              });
            } else {
              this.setState({
                cardDisplay: 1
              });
            }
          }
        }
      }
    });
  };

  storeLength = 0;
  storeId = '';
  cat = 'all';

  tabChange = value => {
    if (value == '1') {
      this.setState({
        tabValue: 1
      });
    } else if (value == '2') {
      this.setState({
        tabValue: 2
      });
    } else if (value == '3') {
      this.setState({
        tabValue: 3
      });
    } else if (value == '4') {
      this.setState({
        tabValue: 4
      });
    }
  };

  render() {
    const { tabValue, cardDisplay } = this.state;
    const currentLanguage = getLanguage();

    let settingShippingLearnMoreLink =
      'https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/';
    let mappingProductLearnMoreLink =
      'https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/';
    let bulkOrderFunctionLearnMoreLink =
      'https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/';

    if (currentLanguage === 'fr-FR') {
      settingShippingLearnMoreLink =
        'https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/';
      mappingProductLearnMoreLink =
        'https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/';
      bulkOrderFunctionLearnMoreLink =
        'https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/';
    }

    return (
      <>
        <div style={{ width: '100%' }} className={styles.homeMainWrap}>
          <div style={{ width: '932px', overflow: 'hidden', margin: '0 auto' }}>
            <div className={styles.slogan}>
              <h3>{intl.get('home.welcome')}</h3>
            </div>
            {/* <AdBanner style={{ marginBottom: 30 }} /> */}
            <ProductPoolBanner showBtn dispatch={this.props.dispatch} />
            {/* 引导页卡片2 */}
            <div className={styles.NoviceGuidance_tab}>
              <div className={styles.tabTitle}>
                <p>{intl.get('home.move')}</p>
                <span
                  style={
                    cardDisplay == 1
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <a
                    onClick={() => {
                      this.postNoviceGuidance('home_2', 0);
                    }}
                  >
                    {intl.get('home.close')}
                  </a>
                </span>
                <span
                  style={
                    cardDisplay == 1
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <a
                    style={{ color: '#c4cdd5' }}
                    onClick={() => {
                      this.postNoviceGuidance('home_2', 1);
                    }}
                  >
                    {intl.get('home.open')}
                  </a>
                </span>
              </div>
              <Tabs
                defaultActiveKey="1"
                tabPosition="left"
                style={
                  cardDisplay == 1 ? { display: 'block' } : { display: 'none' }
                }
              >
                <TabPane
                  tab={
                    <p>
                      <Icon type="shop" theme="outlined" />
                      {intl.get('home.tab_title.add_shopify_store')}
                    </p>
                  }
                  key="1"
                >
                  <div className={styles.NoviceGuidance_list1}>
                    <h3>{intl.get('home.add_shopify_store.title')} </h3>
                    <p>
                      <span />
                      {intl.get('home.add_shopify_store.p1')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.add_shopify_store.p2')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.add_shopify_store.p3')}{' '}
                      <a href="/app/settings?id=11" track-type="home" track-name="linkToSyncShopifyOrders">
                        {intl.get('home.add_shopify_store.p4')}
                      </a>{' '}
                      <br />&nbsp; {intl.get('home.add_shopify_store.p5')}
                    </p>
                    <Button
                      type="link"
                      className={styles.linkForFree}
                      href="https://apps.shopify.com/dsers"
                      target="_blank"
                      track-type="home"
                      track-name="linkToShopifyStore"
                    >
                      {intl.get('home.add_shopify_store.btn')}
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <p>
                      <Icon type="idcard" theme="outlined" />
                      {intl.get('home.tab_title.aliExpress_account')}
                    </p>
                  }
                  key="2"
                >
                  <div className={styles.NoviceGuidance_list3}>
                    <h3>{intl.getHTML('home.aliExpress_account.title')}</h3>
                    <p>
                      <span />
                      {intl.get('home.aliExpress_account.p1')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.aliExpress_account.p2')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.aliExpress_account.p3')}
                    </p>
                    <p>{intl.get('home.aliExpress_account.p4')}</p>
                    <div className={styles.isAliWhitelistedIco}>
                      <Icon type="check-circle" theme="filled" />&nbsp;&nbsp;{intl.get(
                        'top.over_bind'
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <p>
                      <Icon type="user-add" theme="outlined" />
                      {intl.get('home.tab_title.white_list')}
                    </p>
                  }
                  key="3"
                >
                  <div className={styles.NoviceGuidance_list4}>
                    <h3>{intl.getHTML('home.white_list.title')}</h3>
                    <p>
                      <span />
                      {intl.get('home.white_list.p1')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.white_list.p2')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.white_list.p3')}
                    </p>
                    <Button type="primary" style={{ borderRadius: '2px' }}>
                      <a
                        href="https://home.aliexpress.com/dropshipper/join_drop_shipper.htm"
                        target="_blank"
                        track-type="home"
                        track-name="linkToApplyWhitelist"
                      >
                        {intl.get('home.white_list.btn')}
                      </a>
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <p>
                      <Icon type="clock-circle" theme="outlined" />
                      {intl.get('home.tab_title.setting_shipping')}
                    </p>
                  }
                  key="5"
                >
                  <div className={styles.NoviceGuidance_list5}>
                    <img src={setShipping} />
                    <h5>
                      <a href={settingShippingLearnMoreLink} target="_blank" track-type="home" track-name="linkToSettingShippingBlog">
                        {intl.get('home.learn_more')}
                      </a>
                    </h5>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <p>
                      <Icon type="gift" theme="outlined" />
                      {intl.get('home.tab_title.mapping_product')}
                    </p>
                  }
                  key="6"
                >
                  <div className={styles.NoviceGuidance_list5}>
                    <img src={mappingProduct} />
                    <h5>
                      <a href={mappingProductLearnMoreLink} target="_blank" track-type="home" track-name="linkToMappingProductBlog">
                        {intl.get('home.learn_more')}
                      </a>
                    </h5>
                  </div>
                </TabPane>
                <TabPane
                  key="4"
                  tab={
                    <p id="guideBubble5">
                      <Icon type="api" theme="outlined" />
                      {intl.get('home.tab_title.chrome_extension')}
                    </p>
                  }
                >
                  <div className={styles.NoviceGuidance_list2}>
                    <h3>{intl.getHTML('home.chrome_extension.title')}</h3>
                    <p>
                      <span />
                      {intl.get('home.chrome_extension.p1')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.chrome_extension.p2')}
                    </p>
                    <p>
                      <span />
                      {intl.get('home.chrome_extension.p3')}
                    </p>

                    <Button type="primary" style={{ borderRadius: '2px' }}>
                      <a
                        href="https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam?hl=en-US"
                        target="_blank"
                        track-type="home"
                        track-name="downloadChromeExtension"
                      >
                        {intl.get('home.chrome_extension.btn')}
                      </a>
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <p>
                      <Icon type="file-done" theme="outlined" />
                      {intl.get('home.tab_title.bulk_order_function')}
                    </p>
                  }
                  key="7"
                >
                  <div className={styles.NoviceGuidance_list5}>
                    <img src={multipleOrder} />
                    <h5>
                      <a href={bulkOrderFunctionLearnMoreLink} target="_blank" track-type="home" track-name="linkToBulkOrderBlog">
                        {intl.get('home.learn_more')}
                      </a>
                    </h5>
                  </div>
                </TabPane>
              </Tabs>
            </div>

            <div className={styles.videoWrap}>
              <div className={styles.videoBoxTitle}>
                <h2>{intl.get('home.more_about')}</h2>
              </div>
              <div className={styles.videoBox}>
                <div className={styles.videoBoxTop}>
                  <h3>{intl.get('home.video_title_mapping')}</h3>
                </div>
                <div className={styles.video}>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/fcYNKwu_oSk?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                    frameBorder="0"
                    allow="autoplay;encrypted-media"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className={styles.videoBox}>
                <div className={styles.videoBoxTop}>
                  <h3>{intl.get('home.video_title_place_order')}</h3>
                </div>
                <div className={styles.video}>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/6Em8KwD68-U?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                    frameBorder="0"
                    allow="autoplay;encrypted-media"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className={styles.videoBoxLast}>
                <div className={styles.videoBoxTop}>
                  <h3>{intl.get('home.other_features')}</h3>
                </div>
                <div className={styles.videoBoxCon}>
                  <div
                    className={styles.videoLeft}
                    style={
                      tabValue == 1 ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    <iframe
                      width="100%"
                      height="197"
                      src="https://www.youtube.com/embed/3RknbSFRDc4?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                      frameBorder="0"
                      allow="autoplay;encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <div
                    className={styles.videoLeft}
                    style={
                      tabValue == 2 ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    <iframe
                      width="100%"
                      height="197"
                      src="https://www.youtube.com/embed/tPfMzWBnCSM?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                      frameBorder="0"
                      allow="autoplay;encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <div
                    className={styles.videoLeft}
                    style={
                      tabValue == 3 ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    <iframe
                      width="100%"
                      height="197"
                      src="https://www.youtube.com/embed/4hw8BjS48P4?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                      frameBorder="0"
                      allow="autoplay;encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <div
                    className={styles.videoLeft}
                    style={
                      tabValue == 4 ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    <iframe
                      width="100%"
                      height="197"
                      src="https://www.youtube.com/embed/eMS-C04TVoI?rel=0&showsearch=0&loop=1&ap=%2526fmt%3D22"
                      frameBorder="0"
                      allow="autoplay;encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <div className={styles.videoRight}>
                    <p
                      onClick={() => {
                        this.tabChange('1');
                      }}
                      style={tabValue == 1 ? { fontWeight: '600' } : {}}
                    >
                      <span>
                        {tabValue == 1 ? (
                          <img src={playYes} />
                        ) : (
                          <img src={playNo} />
                        )}
                      </span>
                      {intl.get('home.video_title_add_store')}
                    </p>
                    <p
                      onClick={() => {
                        this.tabChange('2');
                      }}
                      style={tabValue == 2 ? { fontWeight: '600' } : {}}
                    >
                      <span>
                        {tabValue == 2 ? (
                          <img src={playYes} />
                        ) : (
                          <img src={playNo} />
                        )}
                      </span>
                      {intl.get('home.video_title_bind_ae')}
                    </p>
                    <p
                      onClick={() => {
                        this.tabChange('3');
                      }}
                      style={tabValue == 3 ? { fontWeight: '600' } : {}}
                    >
                      <span>
                        {tabValue == 3 ? (
                          <img src={playYes} />
                        ) : (
                          <img src={playNo} />
                        )}
                      </span>
                      {intl.get('home.video_title_import_products')}
                    </p>
                    <p
                      onClick={() => {
                        this.tabChange('4');
                      }}
                      style={tabValue == 4 ? { fontWeight: '600' } : {}}
                    >
                      <span>
                        {tabValue == 4 ? (
                          <img src={playYes} />
                        ) : (
                          <img src={playNo} />
                        )}
                      </span>
                      {intl.get('home.video_title_fulfill_manually')}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.bottomLine}>
                <p>{intl.get('home.bottom_line')}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
